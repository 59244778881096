import { Row, Col } from 'react-bootstrap';
import ListItem from './ListItem/ListItem';

export interface Props {
    passwordLength: number;
    containsUpperAndLowerCase: boolean;
    alphanumeric: boolean;
    containsSpecialChar: boolean;
}

export const validStyle = "bi bi-check-circle-fill text-success";
export const invalidStyle = "bi bi-check-circle-fill veryLightGreyText";


export default function NewPasswordRequirementsList({
    passwordLength,
    containsUpperAndLowerCase,
    alphanumeric,
    containsSpecialChar,
}: Props) {


    return (
        <>

            <Row className="mt-2">
                <Col>
                    <div style={{ textAlign: "center" }}>
                        <ul className="text-muted list-unstyled" style={{ display: "inline-block", textAlign: "left" }}>
                            <ListItem isValid={passwordLength > 11} text="at least 12 characters" />

                            <ListItem isValid={containsUpperAndLowerCase} text="upper &amp; lower case" />
                        </ul>
                    </div>
                </Col>
                <Col>
                    <div style={{ textAlign: "center" }}>
                        <ul className="text-muted list-unstyled" style={{ display: "inline-block", textAlign: "left" }}>
                            <ListItem isValid={alphanumeric} text="alphanumeric" />
                            <ListItem isValid={containsSpecialChar} text="at least 1 special character" />
                        </ul>
                    </div>
                </Col>
            </Row>

        </>
    )
}
