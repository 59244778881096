import { invalidStyle, validStyle } from '../NewPasswordRequirementsList'

interface Props {
    isValid: boolean;
    text: string;
}

export default function ListItem({ isValid, text }: Props) {
    return (
        <li className={isValid ? "text-success" : "text-muted"}>
            <i className={isValid ? validStyle : invalidStyle}></i> {text}
        </li>
    )
}