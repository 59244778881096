import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { Formik } from "formik";
import { Form, Button, Spinner } from 'react-bootstrap';
import { EmailFormValues, SubmitEmailFunction } from '../../helpers/interfaces';

interface Props {
    isSubmitting: boolean;
    submit: SubmitEmailFunction;
}

export default function EmailAddressForm({ isSubmitting, submit }: Props) {

    const initialValues = {
        email: ""
    }

    const schema = Yup.object().shape({
        email: Yup.string()
            .email()
            .required('Email is required')
    });





    const validate = (values: EmailFormValues) => {
        let errors: any = {}

        if (values.email !== "") {
            let str = values.email;
            if (str.includes(" ")) {
                errors.email = "Spaces are not allowed in email"
            }
        }

        return errors
    }


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={submit}
                validationSchema={schema}
            >
                {(formik) => {
                    const {
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        handleBlur,
                    } = formik;
                    return (
                        <>


                            <Row>

                                <form onSubmit={handleSubmit}>

                                    {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
                                    {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}


                                    <Form.Group controlId="exampleForm.email">
                                        <Form.Label>Enter your email address</Form.Label>
                                        <div>
                                            <Col sm={12} lg={6} style={{margin: 'auto'}}>

                                                <Form.Control
                                                    type="text"
                                                    className="d-inline form-control"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isSubmitting}
                                                    data-testid="emailInput"
                                                    autoFocus={true}
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="text-danger">{errors.email}</div>
                                                )}
                                            </Col>
                                        </div>

                                    </Form.Group>

                                    <div className="mt-4">
                                        <Button type="submit" variant="primary" disabled={isSubmitting} data-testid="submitResetPasswordFormBtn">
                                            {isSubmitting ?
                                                <>
                                                    <Spinner animation="border" role="status" size="sm" variant="light">
                                                        <span className="visually-hidden">Submitting...</span>
                                                    </Spinner> Submitting...
                                                </>
                                                :
                                                "Submit"
                                            }
                                        </Button>
                                    </div>
                                </form>
                            </Row>

                        </>
                    )
                }}
            </Formik>
        </div>
    )
}