import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { eyeIconSrc, eyeSlashIconSrc } from '../../styles/icons/icons';
import * as Yup from 'yup';
import { Formik } from "formik";
import NewPasswordRequirementsList from '../NewPasswordRequirementsList/NewPasswordRequirementsList';
import { Form, Button, Spinner } from 'react-bootstrap';
import { SubmitNewPasswordFunction } from '../../helpers/interfaces';


interface Props {
    isSubmitting: boolean;
    submit: SubmitNewPasswordFunction;
}

export default function ResetPasswordForm({ isSubmitting, submit }: Props) {



    const initialValues = {
        newPassword: "",
        confirmNewPassword: ""
    }


    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleShowPassword = () => setShowPassword(!showPassword);

    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);




    const schema = Yup.object().shape({
        newPassword: Yup.string()
            .required('New password is required')
            .min(12, 'New password must be at least 12 characters')
            .max(30, 'New password cannot be more than 30 characters')
            .matches(/[a-z]/g, 'New password must contain a lowercase letter')
            .matches(/[A-Z]/g, 'New password must contain an uppercase letter')
            .matches(/\d/, 'New password must contain at least one number')
            .matches(/\W/, 'New password must contain at least one symbol')
        ,
        confirmNewPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('newPassword'), null], "New password and confirm new password must match")
    });


    const [passwordLength, setPasswordLength] = useState(0);
    const [containsUpperAndLowerCase, setContainsUpperAndLowerCase] = useState(false);
    const [alphanumeric, setAlphanumeric] = useState(false);
    const [containsSpecialChar, setContainsSpecialChar] = useState(false);




    interface FormValues {
        newPassword: string;
        confirmNewPassword: string;
    }

    const validate = (values: FormValues) => {
        let errors: any = {}


        if (values.newPassword !== "") {

            let str = values.newPassword;

            // Set password length
            setPasswordLength(values.newPassword.length);

            // Check for lowercase & uppercase
            if (str.match(/[a-z]/g) && str.match(/[A-Z]/g)) {
                setContainsUpperAndLowerCase(true);
            } else {
                setContainsUpperAndLowerCase(false);
            }

            // Check for alphanumeric
            if (str.match(/\d/) && (str.match(/[a-z]/) || str.match(/[A-Z]/))) {
                setAlphanumeric(true);
            } else {
                setAlphanumeric(false)
            }

            // Check for special char
            if (str.match(/[-+_!@#$%^&*.,?]/)) {
                setContainsSpecialChar(true);
            } else {
                setContainsSpecialChar(false)
            }


            if (values.newPassword.includes(" ")) {
                errors.newPassword = "Spaces are not allowed in password"
            }
        }

        return errors
    }


    const inputImgStyle = { margin: "-30px", cursor: "pointer", paddingRight: "30px" };

    return (

        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={submit}
            validationSchema={schema}
        >
            {(formik) => {
                const {
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    handleBlur,
                } = formik;
                return (
                    <>
                        <Row>
                            <Col>
                                <form onSubmit={handleSubmit}>

                                    {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
                                    {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}

                                    <h6 className="mt-3 fw-normal">New password must fulfill the following:</h6>
                                    <Row>
                                        <Col sm={12} lg={6} style={{ margin: 'auto' }}>
                                            <NewPasswordRequirementsList
                                                containsSpecialChar={containsSpecialChar}
                                                passwordLength={passwordLength}
                                                containsUpperAndLowerCase={containsUpperAndLowerCase}
                                                alphanumeric={alphanumeric}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col sm={12} lg={6} style={{ margin: 'auto' }}>
                                            <Form.Group controlId="exampleForm.newPassword">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control
                                                    type={showPassword ? "text" : "password"}
                                                    className="d-inline"
                                                    name="newPassword"
                                                    value={values.newPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isSubmitting}
                                                    maxLength={30}
                                                    onPaste={e => { e.preventDefault(); }}
                                                />
                                                <img
                                                    src={showPassword ? eyeIconSrc : eyeSlashIconSrc}
                                                    onClick={toggleShowPassword}
                                                    style={inputImgStyle}
                                                    alt="showPassword"
                                                />
                                                {errors.newPassword && touched.newPassword && (
                                                    <div className="text-danger">{errors.newPassword}</div>
                                                )}

                                            </Form.Group>

                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col sm={12} lg={6} style={{ margin: 'auto' }}>
                                            <Form.Group controlId="exampleForm.confirmNewPassword">
                                                <Form.Label>Confirm new password</Form.Label>
                                                <Form.Control
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    className="d-inline form-control"
                                                    name="confirmNewPassword"
                                                    value={values.confirmNewPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isSubmitting}
                                                    maxLength={30}
                                                    onPaste={e => { e.preventDefault(); }}
                                                />
                                                <img
                                                    src={showConfirmPassword ? eyeIconSrc : eyeSlashIconSrc}
                                                    onClick={toggleShowConfirmPassword}
                                                    style={inputImgStyle}
                                                    alt="showConfirmPassword"
                                                />
                                                {errors.confirmNewPassword && touched.confirmNewPassword && (
                                                    <div className="text-danger">{errors.confirmNewPassword}</div>
                                                )}
                                            </Form.Group>

                                        </Col>
                                    </Row>

                                    <div className="mt-4">
                                        <Button type="submit" variant="primary" disabled={isSubmitting} data-testid="submitResetPasswordFormBtn">
                                            {isSubmitting ?
                                                <>
                                                    <Spinner animation="border" role="status" size="sm" variant="light">
                                                        <span className="visually-hidden">Changing...</span>
                                                    </Spinner> Changing...
                                                </>
                                                :
                                                "Change"
                                            }
                                        </Button>{' '}
                                    </div>
                                </form>

                            </Col>
                        </Row>

                    </>
                )
            }}
        </Formik >
    )
}