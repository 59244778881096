import * as Yup from 'yup';
import { Row, Col } from 'react-bootstrap';
import { Formik } from "formik";
import { Form, Button, Spinner } from 'react-bootstrap';
import { ResetCodeFormValues, SubmitResetCodeFunction } from '../../helpers/interfaces';

interface Props {
    isSubmitting: boolean;
    submit: SubmitResetCodeFunction;
}

export default function CodeEntryForm({ isSubmitting, submit }: Props) {

    const initialValues = {
        code: ""
    }

    const schema = Yup.object().shape({
        code: Yup.string()
            .required('Code is required')
    });


    const validate = (values: ResetCodeFormValues) => {
        let errors: any = {}

        if (values.code !== "") {
            let str = values.code;
            if (str.includes(" ")) {
                errors.code = "Spaces are not allowed in code"
            }
        }

        return errors
    }


    return (
        <div>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={submit}
                validationSchema={schema}
            >
                {(formik) => {
                    const {
                        values,
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        handleBlur,
                    } = formik;
                    return (
                        <>
                            <form onSubmit={handleSubmit}>

                                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
                                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}


                                <Form.Group controlId="exampleForm.resetCode">
                                    <Form.Label>Enter your reset code</Form.Label>
                                    <Row>
                                        <Col sm={12} lg={6} style={{ margin: 'auto' }}>
                                            <Form.Control
                                                type="text"
                                                className="d-inline form-control"
                                                name="code"
                                                value={values.code}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={isSubmitting}
                                                data-testid="resetCodeInput"
                                                maxLength={6}
                                            />
                                            {errors.code && touched.code && (
                                                <div className="text-danger">{errors.code}</div>
                                            )}
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <div className="mt-4">
                                    <Button type="submit" variant="primary" disabled={isSubmitting} data-testid="submitResetCodeFormBtn">
                                        {isSubmitting ?
                                            <>
                                                <Spinner animation="border" role="status" size="sm" variant="light">
                                                    <span className="visually-hidden">Submitting...</span>
                                                </Spinner> Submitting...
                                            </>
                                            :
                                            "Submit"
                                        }
                                    </Button>
                                </div>
                            </form>
                        </>
                    )
                }}
            </Formik>
        </div>
    )
}