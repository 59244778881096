import { useContext, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import API from '../../utils/API';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import { useParams } from 'react-router-dom';
import { ConfirmResetCodeFormValues, SubmitNewPasswordValues } from '../../helpers/interfaces';
import { convertBase64ToString, convertStringToBase64 } from '../../utils/helpfulFunctions';
import { EnvironmentContext, LoggerContext } from '../../App';
import CodeEntryForm from '../../components/CodeEntryForm/CodeEntryForm';
import RedirectionNotice from '../../components/RedirectionNotice/RedirectionNotice';


export default function ResetWithCode() {

    const environmentSettingsContext = useContext(EnvironmentContext);
    const apiURL = environmentSettingsContext.backendApiUrl;
    const { REACT_APP_REDIRECTION_URL } = environmentSettingsContext;
    const logger = useContext(LoggerContext);

    type UrlParams = {
        email: string;
    }

    const { email: emailBase64Encoded } = useParams<UrlParams>();


    let emailDecoded = convertBase64ToString(emailBase64Encoded || "");


    const [isLoading, setIsLoading] = useState(false);
    const [isResetCodeValid, setIsResetCodeValid] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);
    const [intervalVal, setIntervalVal] = useState(5);

    const [hasResetCodeBeenEntered, setHasResetCodeBeenEntered] = useState(false);

    const [resetCodeError, setResetCodeError] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);

    const decreaseIntervalVal = () => {
        setIntervalVal(intervalVal => intervalVal - 1);
    }

    const redirect = useCallback(() => {
        // handle the redirection
        return window.location.href = REACT_APP_REDIRECTION_URL || "";
    }, [REACT_APP_REDIRECTION_URL]);

    const beginRedirectCountdown = () => {
        setIsRedirecting(true);
        setTimeout(redirect, 5000);
        setInterval(decreaseIntervalVal, 1000);
    }


    const confirmResetCode = (values: ConfirmResetCodeFormValues) => {
        const code = values.code;
        logger.info('emailBase64Encoded from url params is: ', emailBase64Encoded);
        logger.info('========================');
        logger.info(`email decoded is ${emailDecoded}`);
        logger.info(`user submitted reset code is: ${code}`);

        if (emailBase64Encoded) {
            logger.info('checking validity of reset code and email...');
            setIsSubmitting(true);

            let resetCodeBase64Encoded = convertStringToBase64(code);

            API.confirmPasswordResetCode(apiURL, emailBase64Encoded, resetCodeBase64Encoded)
                .then(res => {
                    logger.info('confirmation res: ', res);
                    if (res.data === true) {
                        logger.info('RESET CODE IS SUCCESSFULLY VALIDATED!');
                        logger.info('finished loading');
                        setIsResetCodeValid(true);
                        setIsLoading(false);
                        setHasResetCodeBeenEntered(true);
                        setIsSubmitting(false);
                    } else {
                        logger.info('finished loading BUT RESET CODE IS NOT VALID');

                        setIsSubmitting(false);
                        setHasResetCodeBeenEntered(true);

                        setIsResetCodeValid(false);
                        setIsLoading(false);

                        beginRedirectCountdown();
                    }
                })
                .catch(err => {
                    logger.info('ERROR CONFIRMING PASSWORD RESET CODE');
                    logger.info('finished loading but with error');
                    logger.info(err);
                    setIsSubmitting(false);
                    setHasResetCodeBeenEntered(false);

                    setIsResetCodeValid(false);
                    setIsLoading(false);
                    setError(true);

                    beginRedirectCountdown();
                })
        }
    }


    const submit = (values: SubmitNewPasswordValues) => {
        if (values && values.newPassword && emailBase64Encoded) {

            let newPassword = values.newPassword;
            logger.info('newPassword will be: ', newPassword);

            setIsSubmitting(true);

            const newPasswordBase64Encoded = convertStringToBase64(newPassword);

            API.updatePassword(apiURL, emailBase64Encoded, newPasswordBase64Encoded)
                .then(res => {
                    logger.info('===================================');
                    logger.info('PASSWORD SUCCESSFULLY UPDATED');
                    logger.info(res);


                    setIsSubmitting(false);
                    setIsSubmitted(true);
                    setError(false);

                    setResetCodeError(true);

                    setIsRedirecting(true);
                    setTimeout(redirect, 5000);
                    setInterval(decreaseIntervalVal, 1000);
                })
                .catch(err => {
                    logger.info('ERROR ATTEMPTING TO CREATE PASSWORD');
                    logger.info(err);

                    setSubmissionError(true);

                    setIsSubmitting(false);
                    setIsSubmitted(true);
                })
        }
    };


    return (
        <div>
            <h1>Reset Password</h1>

            <Row>
                <Col>

                    {isLoading &&
                        <div>
                            Loading...
                        </div>
                    }

                    {error &&
                        <div className="error p-3">
                            An error has occurred.
                        </div>
                    }


                    {isRedirecting &&
                        <RedirectionNotice intervalVal={intervalVal} />

                    }


                    {!isLoading && !error &&
                        <div>

                            {!hasResetCodeBeenEntered && resetCodeError &&
                                <div className="error p-3">An error has occurred</div>
                            }

                            {!hasResetCodeBeenEntered &&
                                <CodeEntryForm isSubmitting={isSubmitting} submit={confirmResetCode} />
                            }

                            {/* Code has been entered and is valid*/}
                            {hasResetCodeBeenEntered &&
                                <>
                                    {!isResetCodeValid && <div className="error p-3">An error has occurred. Reset code is not valid.</div>}

                                    {isResetCodeValid &&
                                        <div>
                                            {submissionError && <div className="error p-3"> ❌ An error has occurred. Password has not been updated.</div>}
                                            {isSubmitted && <div className="success p-3">✅ Password was successfully updated</div>}
                                            {!isSubmitted &&
                                                <ResetPasswordForm
                                                    isSubmitting={isSubmitting}
                                                    submit={submit}
                                                />
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }

                </Col>
            </Row>
        </div>
    )
}