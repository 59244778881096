import { useEffect, useState, createContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import Reset from './pages/Reset/Reset';
import SubmitEmail from './pages/SubmitEmail/SubmitEmail';
import API from './utils/API';
import { EnvironmentSettings } from './helpers/interfaces';
import pino from 'pino';
import './App.css';
import ResetWithCode from './pages/Reset/ResetWithCode';


const initialEnvironmentSettings = {
  backendApiUrl: "",
  logLevel: "",
  REACT_APP_REDIRECTION_URL: ""
}

export const EnvironmentContext = createContext(initialEnvironmentSettings);

export const LoggerContext = createContext<any>({});


function App() {


  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [logger, setLogger] = useState<any>();


  const [environmentSettings, setEnvironmentSettings] = useState<EnvironmentSettings>({
    backendApiUrl: "",
    logLevel: "",
    REACT_APP_REDIRECTION_URL: ""
  });



  useEffect(() => {

    API.getEnvironmentSettings()
      .then((res: any) => {

        let pinoLogger = pino({
          level: res.data.logLevel || "silent"
        })

        setLogger(pinoLogger);

        if (res.data.logLevel !== "silent" && res.data.logLevel !== "") {
          console.log("log level is not silent - environment settings are: ", res.data)
        }

        setEnvironmentSettings(res.data);
        setLoaded(true);
        setError(false);
      })
      .catch(err => {
        console.log(err);
        setLoaded(false);
        setError(true);
      })

  }, []);









  return (
    <div className="App">


      {error ?
        <div className="p-3 redBadge">
          Error loading page
        </div>
        :


        <EnvironmentContext.Provider value={environmentSettings}>
          <LoggerContext.Provider value={logger}>
            <Container fluid>
              <div className="mt-3 text-center">
                <Row>
                  <Col sm={1} lg={2}></Col>
                  <Col sm={10} lg={8}>
                    <Routes>
                      <Route path="/" element={loaded && <SubmitEmail />} />

                      {/* Note that :email is base64 encoded */}
                      <Route path="/doreset/:email" element={loaded && <ResetWithCode />} />
                      <Route path="/doreset/:resetCodeBase64Encoded/:email" element={loaded && <Reset />} />

                      {/* If no other routes are found, return 404 page */}
                      <Route path="*" element={loaded && <h1>404 Page not found</h1>} />
                    </Routes>
                  </Col>
                  <Col sm={1} lg={2}></Col>
                </Row>

              </div>
            </Container>
          </LoggerContext.Provider>
        </EnvironmentContext.Provider>
      }





    </div>
  );
}

export default App;
