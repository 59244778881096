import { Buffer } from 'buffer';

export const convertStringToBase64 = (str: string) => {
    const result = Buffer.from(str).toString('base64');
    return result;
}

export const convertBase64ToString = (base64: string) => {
    // logger.debug({ base64StrIs: base64 })
    const result = Buffer.from(base64, 'base64').toString('ascii');
    // logger.debug(`stringified from base64 is: ${base64}` );
    return result;
}