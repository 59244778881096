import { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { EnvironmentContext, LoggerContext } from '../../App';
import EmailAddressForm from '../../components/EmailAddressForm/EmailAddressForm';
import { EmailFormValues } from '../../helpers/interfaces';
import { checkLgIconSrc } from '../../styles/icons/icons';
import API from '../../utils/API';
import { convertStringToBase64 } from '../../utils/helpfulFunctions';



export default function SubmitEmail() {

    const environmentSettingsContext = useContext(EnvironmentContext);
    const apiURL = environmentSettingsContext.backendApiUrl;
    const logger = useContext(LoggerContext);


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);


    const submit = (values: EmailFormValues) => {
        setIsSubmitting(true);

        const base64EncodedEmail = convertStringToBase64(values.email);
        logger.info('base64EncodedEmail: ', base64EncodedEmail);

        API.checkEmail(apiURL, base64EncodedEmail)
            .then((res: any) => {
                logger.info(res);
                setIsSubmitting(false);
                setIsSubmitted(true);
            })
            .catch((err: any) => {
                logger.info(err);
                setIsSubmitting(false);
                setIsSubmitted(true);
            })
    }



    return (
        <div>
            <h1>Reset Password</h1>


            <Row>
                <Col>
                    {/* Show the same 'success' mesage whether the user is found or not */}

                    {isSubmitted &&
                        <div className="success p-3">
                            <img src={checkLgIconSrc} alt="check" /> A reset link has been sent to your email, if it exists
                        </div>
                    }

                    {!isSubmitted &&
                        <EmailAddressForm
                            isSubmitting={isSubmitting}
                            submit={submit}
                        />
                    }
                </Col>
            </Row>
        </div>
    )
}