
interface Props {
    intervalVal: Number;
}

export default function RedirectionNotice({ intervalVal }: Props) {

    const interval = intervalVal > 0 ? intervalVal.toString() : "0";
    
    return (
        <div>
            <h2>Redirecting to DoDMETS in {interval}...</h2>
        </div>
    )
}